<template>
  <div class="home">
    <div class="news">
        <div class="news__head"></div>
        <div class="news__items">
            <div class="news__slides">
                <div v-if="posts.length > 1">
                    <VueSlickCarousel v-bind="newsCarouselSettings" @swipe="onSwipe">
                        <div v-for="post in posts" :key="post.id" class="news__slide" v-bind:data-postid="post.id">
                            <h3 class="h5">{{ post.title.rendered }}</h3>
                        </div>
                    </VueSlickCarousel>
                </div>
            </div>
        </div>
    </div>

    <div class="tiles">
        <router-link to="/page/6649" class="tiles__tile tiles__tile--third tiles__tile--pink"><div><img src="../assets/img/chat.svg" class="icon"> <h3 class="label">Chatten</h3></div></router-link>
        <router-link to="/page/6647" class="tiles__tile tiles__tile--third tiles__tile--green"><div><img src="../assets/img/graduation-hat.svg" class="icon"> <h3 class="label">Het modernste gymnasium</h3></div></router-link>
        <a href="mailto:info@blaisepascalcollege.nl" _target="blank" class="tiles__tile tiles__tile--third tiles__tile--orange"><div><img src="../assets/img/mail.svg" class="icon"><h3 class="label">Mail je vraag</h3></div></a>
        <router-link to="/page/6645" class="tiles__tile tiles__tile--third tiles__tile--teal"><img src="../assets/img/opendag-badge.svg" class="solo solo--medium"></router-link> <!-- Open Huis -->
        <div class="tiles__tile tiles__tile--third"><img src="../assets/img/stamp.png" class="solo solo--big"></div>
        <router-link to="/page/6657" class="tiles__tile tiles__tile--third tiles__tile--pink"><img src="../assets/img/openlessen-badge.svg" class="solo solo--medium"></router-link> <!-- Open Lessen -->
        <a href="https://www.blaisepascalcollege.nl/wp-content/uploads/2021/11/web2-Leerlinggids-A5-Blaise-Pascal-college.pdf" target="_blank" class="tiles__tile tiles__tile--third tiles__tile--purple"><div><img src="../assets/img/folder.svg" class="icon"> <h3 class="label">Schoolgids</h3></div></a>
        <a href="https://www.facebook.com/blaisepascalcollege/" _target="blank" class="tiles__tile tiles__tile--third tiles__tile--fb"><img src="../assets/img/facebook.svg" class="solo solo--sm"></a>
        <a href="https://www.instagram.com/blaisepascalcollege/" _target="blank" class="tiles__tile tiles__tile--third tiles__tile--black"><img src="../assets/img/instagram.svg" class="solo solo--sm"></a>

    </div>

    <div class="video">
        <div class="embed-responsive embed-responsive-16by9">
            <div class="embed-responsive-item">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/wokH7rwbuI4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'Home',
  components: { VueSlickCarousel },
  data() {
    return {
      postsUrl: 'https://www.blaisepascalcollege.nl/wp-json/wp/v2/posts?tags=59', // Only posts with the tag "welkom-op-het-blaise"
      newsCarouselSettings: {
          arrows: false,
          dots: true,
          swiping: false,
          adaptiveHeight: true
      },
      posts: [],
      deferredPrompt: null,
      
    }
  },
  methods: {
    getPosts() {
      axios.get(this.postsUrl, {params: {}})
        .then(response => {
          this.posts = response.data;
        }) 
    },
    onSwipe() {
        this.newsCarouselSettings.swiping = true;
    }
  },
  mounted() {
    this.getPosts();
  },
  updated() {
    if (!$(this.$el).find('.news__slides').hasClass('initialized') && $(this.$el).find('.news__slides .news__slide').length > 0) {
        $(this.$el).find('.news__slides').addClass('initialized');
        $('.news__slide').click((e) => {
            if (!this.newsCarouselSettings.swiping) {
                const postId = $(e.currentTarget).data('postid');

                this.$router.push({ path: `post/${postId}` })
            }
        });

        $(this.$el).find('.news__slides .slick-slider').mouseup(() => {
            setTimeout(() => {
                this.newsCarouselSettings.swiping = false;
            }, 50);
        });
    }
  }
}
</script>
