<template>
  <div class="post">
    <div v-if="post">
      <h1>{{ post.title.rendered }}</h1>

      <div class="date mb-4">{{ getPostDate(post.date) }}</div>
      
      <div v-html="post.content.rendered"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'Post',
  data() {
    return {
      postUrl: 'https://www.blaisepascalcollege.nl/wp-json/wp/v2/posts/' + this.$route.params.id,
      post: null,
      
    }
  },
  methods: {
    getPost() {
      axios.get(this.postUrl, {params: {}})
        .then(response => {
          this.post = response.data;
        }) 
    },
    getPostDate(date) {
      return moment(date).format('LL');
    }
  },
  mounted() {
    this.getPost();
  },
  updated() {

  }
}
</script>
