<template>
    <!-- <div id="app">
        <div id="nav">
            <router-link to="/">Home</router-link> |
            <router-link to="/about">About</router-link>
        </div>
        <router-view/>
    </div> -->

    <div class="app-container d-flex flex-column">
        <header class="d-flex justify-content-between">
            <router-link to="/" class="btn-back" v-bind:class="{ 'btn-back--show': notHome }"></router-link>
            <h1><router-link to="/">Welkom op het Blaise</router-link></h1>
            <a href="https://www.blaisepascalcollege.nl" class="logo" target="_blank"></a>
        </header>
        
        <div class="flex-grow-1">
            <transition name="fade">
                <router-view></router-view>
            </transition>
        </div>
        
        <footer>
            <a href="https://www.blaisepascalcollege.nl" target="_blank">www.blaisepascalcollege.nl</a>
        </footer>

        <div class="a2hs">
            <div class="notification">
                <h3 class="h1 mb-3 text-white">Installeer deze app!</h3>
                <div class="mb-2"><button class="accept-a2hs">Ja, ik wil deze app installeren</button></div>

                <div><a href="javascript:void(0);" class="text-white reject-a2hs">Nee, bedankt</a></div>
            </div>
            
        </div>
    </div>
</template>
<script>
import '@/assets/scss/app.scss';

export default {
    computed: {
        notHome() {
            if(this.$route.path != "/") {
                return true
            } else {
                return false
            }
        }
    },
    mounted() {
        // $('.news__slides').slick({
        //     adaptiveHeight: true,
        //     slidesToShow: 1,
        //     arrows: false,
        //     dots: true,
        //     infinite: true,
        //     // autoHeight: true,
        // });
    }
}
</script>